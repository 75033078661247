/**
  some global style
*/
@import '~@ausz/ui/dist/styles/global.less';

:root {
  --au-primary-color: #4375e1;
  --au-primary-active-color: #3462d9;
}

:root[theme='light'] {
  --au-primary-color: #2054d9;
  --au-primary-active-color: #4375e1;
}

