@import '~@ausz/ui/dist/styles/variables.less';

@font-color-100: rgba(255, 255, 255, 1);
@font-color-85: rgba(255, 255, 255, 0.85);
@font-color-70: rgba(255, 255, 255, 0.7);
@font-color-50: rgba(255, 255, 255, 0.5);

@border-radius-lg: 12px;
@border-radius-md: 8px;

@font-size-lg: 16px;

@keyframes heart {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 10px);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
