// 以地库项目配置作为缺省值，其他项目需要自身进行重写覆盖

:root {
  --au-primary-color: #039d83;
  --au-primary-hover-color: #077d69;
  --au-primary-active-color: #1eb193;

  // caution：calculate from primary-color below，if primary-color change， change these either!
  --au-bg-color-active: fade(#039d83, 50%);
  --au-bg-color-selected: fade(#039d83, 12%);
  --au-select-item-selected-bg: #0eb94529;

  // end
  --au-success-color: #52c41a;
  --au-success-hover-color: #389e0d;
  --au-success-active-color: #2fc29f;
  --au-warning-color: #faa82d;
  --au-warning-hover-color: #d48806;
  --au-warning-active-color: #ffc53d;
  --au-error-color: #f54e4e;
  --au-error-hover-color: #d92b31;
  --au-error-active-color: #ff6e69;
  --au-link-color: #177ddc;
  --au-link-hover-color: #1765ad;
  --au-link-active-color: #3c9ae8;
  --au-white: #fff;
  --au-black: #000;

  // 中立色（内部值，故用 __开头，不要在组件中直接使用，请使用下面有语意的表达！！！）

  // 标题、输入框文字
  --neutral-1: rgba(255, 255, 255, 0.85);

  // 大部分文字
  --neutral-2: rgba(255, 255, 255, 0.7);

  // 辅助说明文字
  --neutral-3: rgba(255, 255, 255, 0.5);

  // 禁用文字、单位
  --neutral-4: rgba(255, 255, 255, 0.3);

  // 边框颜色，如输入框
  --neutral-5: rgba(255, 255, 255, 0.2);

  // 分割线
  --neutral-6: rgba(255, 255, 255, 0.12);

  // 背景色（background）
  --neutral-7: rgba(255, 255, 255, 0.08);

  // 背景色（适用于大背景下局部需要亮一点的情况 lighter，比如 table-header/input-background）
  --neutral-8: rgba(255, 255, 255, 0.04);
  --au-font-color-btn-primary: #fff;
  --au-font-color-mask: rgba(255, 255, 255, 0.85);
  --au-bg-color-base: #0f1012;
  --au-bg-color-default: #1d1f21;

  // default 增加 0.90 透明度
  --au-bg-color-shallow: rgba(29, 31, 33, 0.9);
  --au-bg-color-layer: #373739;
  --au-bg-color-mask: rgba(29, 33, 41, 0.6);
  --au-bg-color-loading: rgba(26, 26, 26, 0.9);
  --au-bg-color-fill: rgba(255, 255, 255, 0.06); // from arco
  --au-scrollbar-color: #333;
}

:root[theme='light'] {
  --au-primary-color: #00b597;
  --au-primary-hover-color: #1fc2a1;
  --au-primary-active-color: #008f7c;
  --au-bg-color-active: fade(#00b597, 50%);
  --au-bg-color-selected: fade(#00b597, 12%);
  --au-select-item-selected-bg: #0eb59429;
  --neutral-1: rgba(0, 0, 0, 0.85);
  --neutral-2: rgba(0, 0, 0, 0.7);
  --neutral-3: rgba(0, 0, 0, 0.5);
  --neutral-4: rgba(0, 0, 0, 0.25);
  --neutral-5: rgba(0, 0, 0, 0.15);
  --neutral-6: rgba(0, 0, 0, 0.06);
  --neutral-7: rgba(0, 0, 0, 0.04);
  --neutral-8: rgba(0, 0, 0, 0.04);
  --au-bg-color-base: #e6e9eb;
  --au-bg-color-default: #fff;
  --au-bg-color-shallow: rgba(255, 255, 255, 0.9);
  --au-bg-color-layer: #fff;
  --au-bg-color-loading: rgba(239, 239, 239, 0.9);
  --au-bg-color-fill: #f4f5f7;
  --au-scrollbar-color: #d9d9d9;
}
